import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { FileDataDisplay } from "#src/components/DataDisplay/FileDataDisplay";
import { GeocoordinateDataDisplay } from "#src/components/DataDisplay/GeocoordinateDataDisplay";
import { linkToAssetDetailPage } from "#src/utils/links";
import {
  ArrayDataDisplay,
  BooleanDataDisplay,
  CalculatedFieldDataDisplay,
  DataTable,
  DateRangeDataDisplay,
  MathDataDisplay,
  NumberDataDisplay,
  TextDataDisplay,
  type HeaderType,
} from "@validereinc/common-components";
import {
  AttributeDataType,
  DateTimeRangeSchema,
  FileUploadedSchema,
  FormSchemaType,
  FormSubmissionAdapter,
  isMeasurementFormQuestion,
  LookupWithDetailsSchema,
  type FormSubmissionAnswersBySectionMapType,
  type FormSubmissionAnswerType,
  type FormSubmissionType,
  type MeasurementUnitType,
} from "@validereinc/domain";
import {
  findAnswerForQuestion,
  FormCalculatedFieldService,
} from "@validereinc/domain-controllers/logic/forms";
import { CalculatedFieldEquationController } from "@validereinc/domain-controllers/view/forms";
import { DateFormats } from "@validereinc/utilities";
import parseISO from "date-fns/parseISO";
import isNull from "lodash/isNull";
import React from "react";

export const renderFormSubmissionAnswer = (
  answer?: FormSubmissionAnswerType,
  questionId: string,
  {
    measurementUnits,
    getPrecisionByType,
    schema,
    sectionId,
    sectionIdx,
    allAnswers,
    allQuestions,
  }: {
    measurementUnits: MeasurementUnitType[];
    getPrecisionByType: (type: string) => number | null;
    schema: FormSchemaType;
    sectionId: string;
    sectionIdx: number;
    allAnswers: FormSubmissionAnswersBySectionMapType;
    allQuestions: FormSchemaType["config"]["questions"];
  }
) => {
  const question = schema.config.questions[questionId];

  if (!answer) return "-";

  if (question.equation) {
    const equationVariablesAndValues =
      FormCalculatedFieldService.getEquationVariablesAndValuesFromSubmission(
        question.equation,
        sectionId,
        sectionIdx,
        allAnswers,
        allQuestions
      );

    // If the answer is derived from another equation with a date or time unit, the BE calculates and stores it in minutes.
    // Therefore, we must convert the unit.
    const convertedAnswer = FormCalculatedFieldService.convertAnswerIfNecessary(
      equationVariablesAndValues,
      answer.value as number,
      question
    );

    const displayMeasurementUnitId =
      FormCalculatedFieldService.getMeasurementUnitFromQuestion(
        question,
        false
      );
    const displayMeasurementUnit = measurementUnits.find(
      ({ id }) => displayMeasurementUnitId === id
    );

    return (
      <CalculatedFieldDataDisplay
        value={convertedAnswer ?? answer.value}
        displaySlot={(props) => (
          <NumberDataDisplay
            {...props}
            unit={
              displayMeasurementUnit?.name?.symbol ?? displayMeasurementUnitId
            }
          />
        )}
        alignment="right"
        showHint
        isValid
        hintSlot={
          <>
            This was calculated using the equation:{" "}
            <CalculatedFieldEquationController
              equation={question.equation}
              questionsMap={schema.config.questions}
              sourceFieldValuesMap={equationVariablesAndValues}
              getTokens
            >
              {({ tokens }) => (
                <MathDataDisplay.Root>
                  <MathDataDisplay
                    colorScheme="light"
                    style={{ whiteSpace: "nowrap", fontSize: 14 }}
                  >
                    {tokens.map((t, idx) => {
                      if (t.startGroup || t.endGroup) {
                        return <span key={idx}>{t.displayValue}</span>;
                      } else if (t.isVariable) {
                        const displayMeasurementUnit = measurementUnits.find(
                          ({ id }) => t.unit === id
                        );
                        const unit =
                          displayMeasurementUnit?.name?.symbol ?? t.unit;

                        return (
                          <MathDataDisplay.InteractiveGroup
                            key={idx}
                            value={t.value}
                            displayValue={
                              t.value !== undefined &&
                              t.value !== null &&
                              String(t.value) !== ""
                                ? t.value.toString()
                                : null
                            }
                            description={`This is the answer from the question of the same name.${unit ? ` In units: "${unit}"` : ""}`}
                          >
                            &quot;{t.displayValue}&quot;
                          </MathDataDisplay.InteractiveGroup>
                        );
                      } else {
                        return (
                          <span
                            key={idx}
                            dangerouslySetInnerHTML={{
                              __html: t.displayValue,
                            }}
                          ></span>
                        );
                      }
                    })}
                  </MathDataDisplay>
                </MathDataDisplay.Root>
              )}
            </CalculatedFieldEquationController>
          </>
        }
      />
    );
  }

  if (isMeasurementFormQuestion(question)) {
    const displayMeasurementUnit = measurementUnits.find(
      ({ id }) => schema.config.questions[questionId].measurement_unit === id
    );
    const precision = getPrecisionByType(
      schema.config.questions[questionId].measurement_type
    );

    const displayedNumber = (
      <NumberDataDisplay
        value={answer.value}
        unit={
          displayMeasurementUnit?.name?.symbol ??
          schema.config.questions[questionId].measurement_unit
        }
        formattingOpts={{
          ...(precision !== null
            ? {
                overrideOpts: {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: precision,
                },
              }
            : {}),
        }}
      />
    );

    if (!answer?.subject_type || !answer?.subject_id) return displayedNumber;

    return (
      <RoutingLink
        style={{ display: "block", textAlign: "right" }}
        to={{
          pathname: linkToAssetDetailPage(
            answer.subject_type,
            answer.subject_id
          ),
          query: {
            tab: "measurements",
          },
        }}
      >
        {displayedNumber}
      </RoutingLink>
    );
  }

  switch (question.data_type) {
    case "geo_point":
      return <GeocoordinateDataDisplay coordinates={answer.value} />;
    case "file": {
      const verifiedAnswer = FileUploadedSchema.safeParse(answer.value);

      if (verifiedAnswer.error) {
        return "-";
      }

      return (
        <FileDataDisplay
          fileName={verifiedAnswer.data.name}
          fileId={verifiedAnswer.data.ref}
          prefetch={false}
        />
      );
    }
    case "lookup": {
      const verifiedAnswer = LookupWithDetailsSchema.safeParse(answer);

      if (verifiedAnswer.error) {
        return answer?.name ?? "-";
      }

      if (verifiedAnswer.data.entity_type === "form_submission") {
        return verifiedAnswer.data.name;
      }

      return (
        <RoutingLink
          to={linkToAssetDetailPage(
            verifiedAnswer.data.entity_type,
            verifiedAnswer.data.value
          )}
        >
          {verifiedAnswer.data.name}
        </RoutingLink>
      );
    }
    case "multi-pick-list": {
      return <ArrayDataDisplay value={answer.value} />;
    }
    case "pick-list": {
      return <TextDataDisplay value={answer.value} />;
    }
    case "date": {
      return (
        <DataTable.DataRow.DateCell
          value={answer.value}
          withTime={false}
        />
      );
    }
    case "date-time": {
      return (
        <DataTable.DataRow.DateCell
          value={answer.value}
          withTime
        />
      );
    }
    case "date-time-range": {
      const verifiedAnswer = DateTimeRangeSchema.safeParse(answer.value);

      if (verifiedAnswer.error) {
        return "-";
      }

      return (
        <DateRangeDataDisplay
          displayFormat={DateFormats.DATE_TIME}
          value={{
            from: parseISO(verifiedAnswer.data[0]),
            to: parseISO(verifiedAnswer.data[1]),
          }}
        />
      );
    }
    case "boolean": {
      return <BooleanDataDisplay value={answer.value} />;
    }
    case "number": {
      return (
        <DataTable.DataRow.NumberCell
          value={answer.value}
          formattingOpts={{
            ...(Number(answer.value) > 10 ** 6
              ? { fractionDigits: 6 }
              : { overrideOpts: { maximumFractionDigits: 3 } }),
          }}
        />
      );
    }
    case "integer": {
      return (
        <DataTable.DataRow.NumberCell
          value={answer.value}
          formattingOpts={
            Number(answer.value) > 10 ** 6
              ? { fractionDigits: 6 }
              : { overrideOpts: { maximumFractionDigits: 3 } }
          }
        />
      );
    }
    case "string": {
      if (typeof answer.value !== "string") return "-";

      return <DataTable.DataRow.TextCell value={answer.value} />;
    }
    default:
      return "n/a";
  }
};

/**
 * Translate an existing form answers filter object to another filter object
 * that conforms to the filtering restrictions based on the associated form
 * schema
 * @param filters the form answers filter object
 * @param formSchema the associated form schema
 * @returns a filter object that works with the form answers filtering endpoint
 */
export const getFormattedFiltersBasedOnFormSchema = (
  filters: Parameters<
    typeof FormSubmissionAdapter.answers.getListV2
  >[0]["filters"],
  formSchema: FormSchemaType
) => {
  if (!filters || !formSchema) return {};

  return Object.entries(filters).reduce<
    Record<string, string | number | boolean | string[] | null>
  >((newFilters, [filterKey, filterValue]) => {
    const [sectionId, questionId] = filterKey.split(":");
    const matchingSectionConfig = formSchema.config?.sections?.find(
      (s) => s.id === sectionId
    );
    const matchingQuestionConfig = formSchema.config.questions[questionId];

    if (
      (!matchingSectionConfig && !matchingQuestionConfig) ||
      isNull(filterValue) ||
      (typeof filterValue === "string" && filterValue === "")
    ) {
      return newFilters;
    }

    switch (matchingQuestionConfig.type) {
      case "question": {
        switch (matchingQuestionConfig.data_type) {
          // IMPROVE: some data types are not supported for filtering at this time
          case AttributeDataType.DATE_TIME_RANGE:
          case AttributeDataType.FILE:
            return newFilters;
          case AttributeDataType.DATE:
            // REVIEW: Strip the time from the timestamp and only return the date as the BE doesn't support filtering on such a date string
            newFilters[filterKey] =
              typeof filterValue === "string"
                ? filterValue.split("T")[0]
                : filterValue;
            break;
          case AttributeDataType.NUMBER:
          case AttributeDataType.INTEGER:
            newFilters[filterKey] = Number(filterValue);
            break;
          // REVIEW: geo point inputs return an array of [null, null] when no value is provided which is smelly
          case AttributeDataType.GEO_POINT: {
            if (
              !filterValue ||
              !Array.isArray(filterValue) ||
              (Array.isArray(filterValue) &&
                (!filterValue[0] || !filterValue[1]))
            ) {
              return newFilters;
            }

            // REVIEW: BE requires a very strange syntax to query arrays
            newFilters[filterKey] = JSON.stringify(
              filterValue.map((v) => Number(v))
            ).replace(/,/g, ", ");
            break;
          }
          case AttributeDataType.MULTI_PICK_LIST: {
            if (!Array.isArray(filterValue)) return newFilters;

            // REVIEW: BE requires a very strange syntax to query arrays
            newFilters[filterKey] = JSON.stringify(filterValue).replace(
              /,/g,
              ", "
            );
            break;
          }
          default:
            newFilters[filterKey] = filterValue;
            break;
        }
        break;
      }
      case "measurement": {
        newFilters[filterKey] = Number(filterValue);
        break;
      }
    }

    return newFilters;
  }, {});
};

export const renderFormSectionName = (
  sectionId: string,
  section_index: number,
  schema: FormSchemaType
) => {
  const sectionName =
    schema?.config?.sections?.find((section) => section.id === sectionId)
      ?.name || "";

  const sectionIndexAsNumber = Number(section_index);
  return `${sectionName} ${!isNaN(sectionIndexAsNumber) ? sectionIndexAsNumber + 1 : ""}`;
};

export const getHeadersFromSchema = (
  schema: FormSchemaType,
  {
    measurementUnits,
    getPrecisionByType,
  }: {
    measurementUnits: MeasurementUnitType[];
    getPrecisionByType: (type: string) => number | null;
  }
): Array<HeaderType<FormSubmissionType>> => {
  return schema.config?.sections?.map<HeaderType<FormSubmissionType>>((s) => {
    return {
      key: s.id,
      label: `${s.name}${s.is_repeatable ? " (Repeatable)" : ""}`,
      headers: s.questions.map((qid) => ({
        label: schema.config.questions[qid].prompt,
        key: `${s.id}.${qid}`,
        tooltip: schema.config.questions[qid].description,
        minWidth: 180,
        renderComponent: ({ item }) => {
          if (!item.answers || !Array.isArray(item.answers[s.id]))
            return <>n/a</>;

          const firstFilledSectionIdx = item.answers[s.id].findIndex(
            (sec) => !!sec
          );

          const answer = findAnswerForQuestion(
            item.answers,
            qid,
            s.id,
            firstFilledSectionIdx,
            { findFallbackByPrompt: true },
            schema,
            item.form_schema
          );

          const thisAndOtherRepeatedSectionsWithAnswers = item.answers[
            s.id
          ].reduce((count, sec, idx) => {
            if (
              idx === firstFilledSectionIdx ||
              !sec ||
              !Object.keys(sec).length ||
              sec?.[qid] === undefined
            ) {
              return count;
            }

            return count + 1;
          }, 0);

          // IMPROVE: better UI to show multiple answers
          return thisAndOtherRepeatedSectionsWithAnswers > 1 ||
            (thisAndOtherRepeatedSectionsWithAnswers >= 1 &&
              item.answers[s.id].length > 1) ? (
            <>
              {answer !== undefined
                ? "(multiple answers)"
                : "(answers in other sections)"}
            </>
          ) : (
            renderFormSubmissionAnswer(answer, qid, {
              schema,
              measurementUnits,
              getPrecisionByType,
              sectionId: s.id,
              sectionIdx: 0,
              allAnswers: item.answers,
              allQuestions: schema.config.questions,
            })
          );
        },
      })),
    };
  });
};
